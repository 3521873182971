import React from "react"
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter,  Link } from 'react-router-dom'
import '../../../../css/Service.css'
import Orderline from '../../Orderline'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

class OrderlineList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      agree_to_terms: false,
      submitting: false,
      showTermsAndConditions: false,
      submissionErrorMessage: false
    }
  }

  componentDidMount(){
    if (this.props.targetlist.length === 1){
      this.props.targetlist.map(target => {
        if(!(this.props.targetids.map(t => t.id).includes(target.id))){
          this.props.storeTargetIds(target.id, target.name, target.name_with_kick_off, target.teams, target.start_time, target.end_time, target.rank, target.warm_up_match)
        }
      })
    }
  }

  createOrder = (history) => {
    this.setState({submitting: true});

    axios.post("/orders.json",{
        order: {
          customer_id: this.props.customer_id,
          contact_id: this.props.contact_id,
          booking_form_id: this.props.bf_id,
        },
        order_lines: Object.assign({}, this.props.orderlinesArray.filter(o => ((o.service_id !== null && o.requested_quantity !==  "" && o.requested_quantity !==  null) && o.requested_quantity !==  0 )))
      }).then((response) => {
        if ((response.data) && parseInt(response.data.ordernumber) > 0) {
          history.push('/orders/' + response.data.ordernumber);
        } else {
          // Something went wrong
          this.setState({submissionErrorMessage: true});
        }
      }).catch((error) => {
        this.setState({submissionErrorMessage: true});
      });
  }

  toggleAgree = (e) => {
    this.setState({agree_to_terms: !this.state.agree_to_terms});
  }

  formatNumber = (number) => {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  closeModalHandler = () => {
    this.setState({showTermsAndConditions: false});
    return true;
  }

  renderTermsAndConditionsModal = () => {
    let modalBody = <div>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Terms and conditions
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <embed src="/downloads/Terms%20&%20Conditions.pdf" width="100%" height="500px" type="application/pdf"/>
                      </Modal.Body>
                      <Modal.Footer>
                        <a target='_blank' href='/downloads/Terms%20&%20Conditions.pdf' download className="btn btn-outline-info"><i className="fa fa-download"></i> Download</a>
                        <button className="btn btn-outline-secondary" onClick={this.closeModalHandler}>Close</button>
                      </Modal.Footer>
                    </div>

    return <Modal size='lg' show={this.state.showTermsAndConditions} onHide={this.closeModalHandler}>{ modalBody }</Modal>;
  }

  displayTermsAndConditions = (e) => {
    e.preventDefault();
    this.setState({showTermsAndConditions: true});
    return false;
  }

  displayTooltipsAccordingToTos = (submitButton) => {
    if (this.state.agree_to_terms){
      return (submitButton)
    } else {
      return(
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Please check the box confirming you agree with the Terms &amp; Conditions</Tooltip>}>
          <span>
            { submitButton }
          </span>
        </OverlayTrigger>
      )
    }
  }

  sortOrderlinesByRankAndServiceCode = (array) => {
    return array.sort((a, b) =>{
      if (a.orderline.rank == b.orderline.rank) {
        return (b.orderline.serviceCode < a.orderline.serviceCode) ? 1 : -1
      } else {
        return (b.orderline.rank < a.orderline.rank) ? 1 : -1
      }
    })
  }

  render (){
    if (this.state.submissionErrorMessage) {
      return <div>
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Something went wrong!</h4>
            <p>The order creation failed.</p>
            <div>
              <Link to={'/orders'} className="btn btn-lg btn-outline-secondary">
                <i className="fa fa-chevron-left"></i>&nbsp;
                Back to orders
              </Link>
            </div>
          </div>
        </div>
    }
    let sortedOrderlines = this.sortOrderlinesByRankAndServiceCode(this.props.orderlines)

    let orderLines = sortedOrderlines.filter(o => (o.orderline.id !== null && o.orderline.quantity !==  "" && o.orderline.quantity !==  null) && o.orderline.quantity !==  0 ).map((o) => {
      return <Orderline {...o.orderline} displayPrices={true} editable={false} key={o.orderline.id} />
    })

    let disableSubmitButton = !this.state.agree_to_terms;
    if (this.state.submitting) { disableSubmitButton = true; }

    const SubmitButton = withRouter(({ history }) => (
      <button onClick={() => {this.createOrder(history)}}
              className="btn btn-primary btn-lg"
              disabled={ disableSubmitButton ? 'disabled' : '' }
              style={this.state.agree_to_terms ? {} : { pointerEvents: 'none' }}>
        {this.state.submitting ? 'Submitting...' : 'Submit'}
        &nbsp;<i className="fa fa-chevron-right"></i>
      </button>
    ))

    return (
      <div className='orderlines-list-container'>
        { this.renderTermsAndConditionsModal() }
        <div className='orderlines-list show-scrollbar'>
          <div className="orderlines-list-table">
            <table className="table">
              <tbody>
                { orderLines }
              </tbody>
            </table>
          </div>
        </div>
        <footer className="footer step-two">
          <div className="container">
            <ul className="buttons">
              <li>
                <Link to={'/orders/new/2'} onClick={this.props.editOrder} className="btn btn-outline-primary">
                  <i className="fa fa-chevron-left"></i>&nbsp;
                  Modify
                </Link>
              </li>
              <li>
                <Link to={'/orders/new/'} onClick={this.props.cancelOrder} className="btn btn-outline-secondary">
                  <i className="fa fa-ban"></i>&nbsp;
                  Cancel
                </Link>
              </li>
              <li id='submit_button'>
                <div id='order_prices'>
                  Total Price of the Order:&nbsp;
                  { this.props.totalPriceByCurrency.join(" - ") }
                </div>
                <div id="terms_and_conditions">
                  <input type='checkbox' value='1' id='agree_to_terms_and_conditions' onClick={(event) => {this.toggleAgree(event)}}/>
                  <label htmlFor='agree_to_terms_and_conditions'>
                    By ticking this box, you certify being duly appointed to place this order and acknowledge the acceptance of {settings['terms_and_conditions_name']} Terms and Conditions which can be found <a href='#' onClick={(e) => { this.displayTermsAndConditions(e); return false; }}>here</a>.
                  </label>
                </div>
                <div id="submit_button_container">
                  { this.displayTooltipsAccordingToTos(<SubmitButton/>) }
                </div>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    load: state.loading,
    bf_id: state.order.booking_form_id,
    targetids: state.order.target_ids,
    orderlines: state.orderlines,
    orderlinesArray: state.order.orderlines,
    customer_id: state.order.customer_id,
    contact_id: state.order.contact_id,
    targetlist: state.targets,
    totalPrice: state.totalPrice,
    totalPricePermatch: state.totalPricePermatch,
    totalPriceByCurrency: state.totalPriceByCurrency,
    booking_phase: state.booking_phase
  };
}

const mapDispatchToProps = dispatch => {
  return{
    editOrder: () => dispatch({type: "EDIT_ORDER_LINK"}),
    cancelOrder: () => dispatch({type: "CANCEL_ORDER_LINK"}),
    storeTargetIds: (id, name, name_with_kick_off, teams, start_time, end_time, rank, warm_up_match) => dispatch({ type: "STORE_TARGET_IDS", id: id, name: name, name_with_kick_off: name_with_kick_off, teams: teams, start_time: start_time, end_time: end_time, rank: rank, warm_up_match: warm_up_match }),
    resetState: () => dispatch({ type: "RESET_STATE"})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderlineList)
