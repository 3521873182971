import React from "react"
import axios from 'axios';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import * as actionCreators from '../../../../actions/index'
import '../../../../css/target.css'

class TargetList extends React.Component{
  componentDidMount(){
    this.props.fetchAllTargets(this.props.bp_id)
  }

  render () {
    document.getElementsByClassName("btn-logout")[0].style.display = "none"
    let targetsList = null

    if (this.props.targetlist.length > 1){
      targetsList = this.props.targetlist.map( target => {
        let isSelected =  this.props.targetids.map(t =>(t.id)).includes(target.id)
        let className = isSelected ? 'target-box selected' : 'target-box';
        return (
          <div key={target.id} onClick={ () => {isSelected ? this.props.removeTargetIds(target.id) : this.props.storeTargetIds(target.id, target.name, target.name_with_kick_off, target.teams, target.start_time, target.end_time, target.rank, target.warm_up_match)} } className={className}>
            { target.name_with_kick_off }
            &nbsp;
            { target.teams }
          </div>
        )
      })
    } else {
      targetsList = this.props.targetlist.map( target => {
        return (
          <div key={target.id}>
            <h5>
              { target.name_with_kick_off }
              &nbsp;
              { target.teams }
            </h5>
          </div>
        )
      })
    }


    return (
      <div className="col-3 matches-list show-scrollbar" >
        <div>
          { targetsList }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    bp_id: (state.booking_phase ? state.booking_phase.id : null),
    targetlist: state.targets,
    targetids: state.order.target_ids,
    bf_id: state.order.booking_form_id,
    toggled: state.clicked
  };
}

const mapDispatchToProps = dispatch => {
  return{
    fetchAllTargets: (id) => dispatch(actionCreators.fetchTargets(id)),
    storeTargetIds: (id, name, name_with_kick_off, teams, start_time, end_time, rank, warm_up_match) => dispatch({ type: "STORE_TARGET_IDS", id: id, name: name, name_with_kick_off: name_with_kick_off, teams: teams, start_time: start_time, end_time: end_time, rank: rank, warm_up_match: warm_up_match }),
    removeTargetIds: (id) => dispatch({ type: "REMOVE_TARGET_IDS", id: id})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetList)