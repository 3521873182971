import React from "react"
import axios from 'axios';
import PropTypes from "prop-types"
import MrlSelect from '../orderlineform/ordersteponeform/Mrlselect';
import BookingPhaseList from '../orderlineform/ordersteponeform/Bookingphaselist';
import "../../../css/Orderstepone.css"


class Orderstepone extends React.Component {
  render () {
    return (
      <div>
        <div className="order-step-one">
          <h1>Create a new order - Step 1</h1>
        </div>
        <MrlSelect/>
        <BookingPhaseList/>
      </div>
    );
  }
}

export default Orderstepone
