import React from "react"
import ServicesList from '../orderlineform/ordersteptwoform/ServicesList';
import TargetList from '../orderlineform/ordersteptwoform/TargetList';
import "../../../css/Orderstepone.css"
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

class Ordersteptwo extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      instructionsCollaped: false
    }
  }

  componentDidMount(){
    window.addEventListener('beforeunload', this.unloadWarning);
    document.querySelector("#org_logo a").setAttribute('data-turbolinks', 'false');
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.unloadWarning);
    document.querySelector("#org_logo a").setAttribute('data-turbolinks', 'true');
  }

  unloadWarning = (e) => {
    e.preventDefault();
    e.returnValue = "Are you sure to leave the page? You'll lose your current order";
  }

  instructions = () => {
    let booking_phase = this.props.booking_phase;
    let instructionsCollaped = this.state.instructionsCollaped;

    if ((booking_phase.instructions === null) || (booking_phase.instructions == "")) {
      return null;
    }

    return <div>
      <div className={instructionsCollaped ? "alert alert-primary instructions instructions-collapsed" : "alert alert-primary instructions" } role="alert">
        <button className="btn btn-link collapse-instructions-button" onClick={() => { this.setState({instructionsCollaped: (!this.state.instructionsCollaped)}); }}>
          <i className={instructionsCollaped ? "fa fa-angle-down " : "fa fa-angle-up"}></i>
        </button>
        { booking_phase.instructions.split('\n').map(function(item, key) {
            return (
              <span key={key}>
                {item}
                <br/>
              </span>
            )
          }) }
        <br/>
        <b>{settings['timing_instructions']}</b>
        <div className='instructions-overlay'></div>
      </div>
    </div>
  }

  render () {
    let booking_phase = this.props.booking_phase;

    if (booking_phase === null) {
      return <Redirect to="/orders/new"/>;
    }

    return (
      <div className='order-step-two-container'>
        <div className="order-step-two">
          <h1>Create a new order - Step 2</h1>
          { this.instructions() }
        </div>
        <div className="row">
          <TargetList />
          <ServicesList />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    booking_phase: state.booking_phase
  };
}

const mapDispatchToProps = dispatch => {
  return { }
}

export default connect(mapStateToProps, mapDispatchToProps) (Ordersteptwo)

