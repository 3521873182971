import React from "react"
import axios from 'axios';
import PropTypes from "prop-types"
import { BrowserRouter, Router, Route,  Link } from 'react-router-dom'
import '../../../../css/booking_phase.css'
import { connect } from 'react-redux';
import * as actionCreators from '../../../../actions/index'
import NotificationsManager from '../../../extracted_components/NotificationsManager'

class BookingPhaseList extends NotificationsManager {
  _isMounted = false;

  constructor(props){
    super(props);
    this.notificationDOMRef = React.createRef();
    this.state = {
      booking_phases: []
    }
  }

  componentDidMount () {
    this._isMounted = true;
    axios.get('/booking_phases.json')
      .then(response =>{
        if (this._isMounted) {
          this.setState({
            booking_phases: response.data
          });
        }
      }).catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showAlert = (event) => {
    this.addNotification(this.notificationDOMRef, 'Error', 'Please select a ' + settings['customer_name'] + ' first', 'warning')
    event.preventDefault();
    event.stopPropagation();
  }

  customerHasBeenSelected = (event, bphase) => {
    if (this.props.customerId !== null){
      this.props.storeBookingPhase(bphase)
    } else {
      this.showAlert(event)
    }
  }

  render () {
    document.getElementsByClassName("btn-logout")[0].style.display = "none"
    let bookings = null
    let card = null

    if (this.state.booking_phases && this.state.booking_phases.length > 0){
      bookings = this.state.booking_phases.filter(b => b.rank !== null).map((booking_phase) => {
        return(
          <Link key={booking_phase.id} onClick={ (event) => { this.customerHasBeenSelected(event, booking_phase) } } to={"/orders/new/2"} className="card col-3">
            <div  className="card-body">
              { booking_phase.cos_chapter ? <div className="cos_chapter"><div className='arrow-right'/>{ booking_phase.cos_chapter }</div> : null }
              <h5 className="title">{ booking_phase.title }</h5>
              <div className="subtitle">{ booking_phase.subtitle }</div>
              <div className="description">{ booking_phase.description }</div>
              { booking_phase.deadline ? <div className="deadline">Deadline: { booking_phase.deadline }</div> : null }
            </div>
          </Link>
        )
      })
    } else {
      bookings = (
        <p>Loading...</p>
      )
    }

    return (
      <div className="booking-form-list">
        <NotificationsManager notificationDOMRef={ this.notificationDOMRef }/>
        <div className="label">
          Please select a booking form:
        </div>
        <div className="select-mrl-input">
          <div className="row booking-phase-list">
            { bookings }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      bp_id: (state.booking_phase ? state.booking_phase.id : null),
      bf_id: state.order.booking_form_id,
      customerId: state.order.customer_id,
      ts: state.ts
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storeBookingPhase: (booking_phase) => dispatch({ type: "STORE_BOOKING_PHASE", value: booking_phase})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingPhaseList)