import axios from 'axios';

export const fetchTargets = (id) => {
  return dispatch => {
    return axios.get("/targets.json?booking_phase_id=" + id ).then( (response) => {
      dispatch(fetchTargetsAction(response.data))
      if (response.data.length == 1) {
        dispatch(setTarget(response.data[0]));
      }
    });
  }
}

export const setTarget = (target) => {
  return {
    type: "STORE_TARGET_IDS",
    id: target.id,
    name: target.name,
    name_with_kick_off: target.name_with_kick_off,
    teams: target.teams,
    start_time: target.start_time,
    end_time: target.end_time,
    warm_up_match: target.warm_up_match
  }
}

export const fetchTargetsAction = (data) => {
  return {
    type: "FETCH_TARGETS",
    payload: data
  }
}

export const fetchServices = (id) => {
  return dispatch => {
    return axios.get("/services.json?booking_form_id=" + id).then( (response) => {
      dispatch(fetchServicesAction(response.data))
    });
  }
}

export const fetchServicesAction = (data) => {
  return {
    type: "FETCH_SERVICES",
    payload: data
  }
}

