import React from "react"
import axios from 'axios';
import PropTypes from "prop-types"
import { connect } from 'react-redux';

class MrlSelect extends React.Component {
  _isMounted = false;

  state = {
    customers: [],
    loading: true,
    selectedCustomerId: this.props.customer_id,
    selectedContactId: this.props.contact_id
  }

  componentDidMount () {
    this._isMounted = true;

    axios.get('/customers.json')
      .then(response =>{
        let customers = response.data;
        if (this._isMounted) {
          this.setState({
            customers: customers,
            loading: false
          });
        }

        // Set the first customer and contact
        if (this.state.customers.length < 2){
          this.props.storeCustomerIdAndContactId(customers[0].id, customers[0].contact_id)
        }
      }).catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChangeMrl = (event) => {
    if (event.target.value == ','){
      this.props.storeCustomerIdAndContactId(null, null)
    }

    if (event.target.value !== ',' && this.state.customers.length > 1){
      var arr = event.target.value.split(",")
      this.props.storeCustomerIdAndContactId(arr[0], arr[1])

      this.setState({
        selectedCustomerId: arr[0],
        selectedContactId: arr[1]
      });
    }
  }

  render (){
    if ((this.state.loading) || (this.state.customers.length < 2)) {
      return null
    }

    let options = this.state.customers.map((customer) => {
      return (
        <option key={customer.id} value={[customer.id, customer.contact_id].join(',')}>{customer.code}</option>
      )
    })

    return(
      <div className="select-mrl">
        <div className="label">
          Please select the {settings['customer_name']}:
        </div>
        <div className="select-mrl-input">
          <select className="form-control col-4"
                  onChange={(event) => {this.onChangeMrl(event)}}
                  value={[this.state.selectedCustomerId||'', this.state.selectedContactId||''].join(',')}>
            <option value=','>
              Select a {settings['customer_name']}
            </option>
            { options }
          </select>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    customer_id: state.order.customer_id,
    contact_id: state.order.contact_id
  };
}

const mapDispatchToProps = dispatch => {
  return{
    storeCustomerIdAndContactId: (customer_id, contact_id) => dispatch({type: "STORE_CUSTOMER_ID", customer_id: customer_id, contact_id: contact_id}),
    removeCustomerId: () => dispatch({type: "REMOVE_CUSTOMER_ID"}),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (MrlSelect)