import React from "react"
import axios from 'axios';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { BrowserRouter, Router, Route,  Link } from 'react-router-dom'
import * as actionCreators from '../../../../actions/index'
import '../../../../css/Service.css'
import NotificationsManager from '../../../extracted_components/NotificationsManager'
import ReactNotification from "react-notifications-component";
import { inputIsValid } from '../../../extracted_components/ValidationsServices'
import { fetchServicesList } from '../../../../apis/ApiCall'
import DateTime from 'react-datetime';
import '../../../../css/react-datetime.css';
import moment from 'moment';

class ServicesList extends NotificationsManager {
  constructor(props){
    super(props);
    this.orderLinePrice = 0
    this.pricePermatch = 0
    this.notificationDOMRef = React.createRef();
    this.totalPriceByCurrency = []
    this.state = {
      services: [],
      loading: false
    }
  }

  componentDidMount(){
    if (!this.props.edit){
      fetchServicesList(this, this.props.bf_id)
    }
  }

  manageServicesOnChangeHandler = (id, event, attr) => {
    var serviceAttr;
    var servicesList = this.props.edit == true ? this.props.serviceslist : this.state.services

    const service = servicesList.find(service => service.id === id)

    switch(attr) {
      case "start_time":
        inputIsValid(service, event, 'start_time', this)

        if ( event == '' ) {
          service.start_time = ''
          service.end_time = ''
        } else {
          if (typeof(event.format) == 'function') {
            service.start_time = event.format('H:mm')
          }
        }
        break;
      case "end_time":
        inputIsValid(service, event, 'end_time', this)

        if ( event == '' ) {
          service.end_time = ''
        } else {
          if (typeof(event.format) == 'function') {
            service.end_time = event.format('H:mm')
          }
        }
        break;
      case "slot":
        service.slot = event.target.value;
        service.quantity = service.slot ? 1 : 0
        break;
      case "quantity":
        inputIsValid(service, event.target.value, 'quantity', this)
        service.quantity = event.target.value
        break;
      default:
        return;
    }

    const services = [...servicesList]

    if (this.props.edit == false){
      this.setState({ services: services })
    }

    this.calculatePricePerMatch(services)

    this.props.storeServices(services)
  }

  saveOrderline = (service) => {
    this.props.targetlist.map( target => {
      return this.props.storeService(target.name_with_kick_off, target.teams, target.rank, target.warm_up_match, service.quantity, service.id, service.code,
                                     service.description, this.calculatePriceOfService(service), service.price, service.start_time,
                                     service.end_time, service.should_prompt_slot_number, service.slot, service.currency)
    })
  }

  orderIsEmpty = () => {
    let services = []
    if (this.props.edit === false) {
      services = this.state.services
    } else {
      services = this.props.serviceslist
    }

    const selected_services = services.filter(service => ((service.quantity !== null && service.quantity !== 0 && service.quantity !== '') ||
                                                          (service.quantity !== 0 && service.start_time !== null && service.end_time !== null && service.start_time !== '' && service.end_time !== '' && service.start_time != service.end_time) ||
                                                          (service.should_prompt_slot_number == true && service.slot !== '' && service.slot !== null && service.slot !== undefined)))

    if (this.props.targetlist.length === 1){
      // If the booking phase has only one target
      return (selected_services.length === 0)
     } else {
      // If the booking phase has more than one target
      return (this.props.targetids.length === 0) || (selected_services.length === 0)
     }
  }

  servicesGroupByCurrency = (services) => {
    return services.reduce((group, service) => {
      const { currency } = service;
      group[currency] = group[currency] ?? [];
      group[currency].push(service);
      return group;
    }, {});
  }

  multipleCurrenciesFormat = (services, calculationCallback) => {
    const priceWithCurrency = []
    Object.entries(this.servicesGroupByCurrency(services)).map(([key, services]) => {
      if(calculationCallback(services) !== 0){priceWithCurrency.push(key + " " + this.formatNumber(calculationCallback(services)))}
    })
    return priceWithCurrency.length > 0 ? priceWithCurrency : [`0.00 ${services[0].global_currency}`]
  }

  formatPriceByCurrency = (services, calculationCallback) => {
    if(Object.keys(this.servicesGroupByCurrency(services)).length > 1){
      return this.multipleCurrenciesFormat(services, calculationCallback)
    }else{
      return Object.entries(this.servicesGroupByCurrency(services)).map(([key, services]) => key + " " + this.formatNumber(calculationCallback(services)))
    }
  }

  saveServices = () => {
    let services = []
    if (this.props.edit === false) {
      services = this.state.services
    }else{
      services = this.props.serviceslist
    }

    const filter_services = services.filter(service => (service.quantity !== null || service.start_time !== null ) || (service.end_time !== null || service.slot !== null))

    filter_services.map( service => {
      if (!(this.props.targetlist.length === 1)){
        this.props.targetids.map( target => {
          return this.props.storeService(target.name_with_kick_off, target.teams, target.rank, target.warm_up_match, service.quantity, service.id, service.code, service.description,
                                        this.calculatePriceOfService(service), service.price, service.start_time, service.end_time,
                                        service.should_prompt_slot_number, service.slot, target.id, this.calculatePrice(), this.pricePermatch, service.currency, this.totalPriceByCurrency)
        })
      } else {
        this.props.targetlist.map( target => {
          return this.props.storeService(target.name_with_kick_off, target.teams, target.rank, target.warm_up_match, service.quantity, service.id, service.code, service.description,
                                         this.calculatePriceOfService(service), service.price, service.start_time, service.end_time,
                                         service.should_prompt_slot_number, service.slot, target.id, this.calculatePrice(), this.pricePermatch, service.currency, this.totalPriceByCurrency)
        })
      }
    })
  }

  formatNumber = (number) => {
    if (number === "O.OO") {number = 0}
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  getTimeBasedOnTargetTime(time, targetTime) {
    const hour = parseInt(time.split(":")[0])
    const minute = parseInt(time.split(":")[1])

    let prefix = targetTime.split('T')[0]
    let suffix = targetTime.split('T')[1].split('')

    if(hour > 9) {
      suffix[0] = String(hour)[0]
      suffix[1] = String(hour)[1]
    } else {
      suffix[0] = '0'
      suffix[1] = String(hour)[0]
    }

    if(minute > 9) {
      suffix[3] = String(minute)[0]
      suffix[4] = String(minute)[1]
    } else {
      suffix[3] = '0'
      suffix[4] = String(minute)[0]
    }

    return `${prefix}T${suffix.join('')}`
  }

  // Javascript version of Target#get_date_for_time(time)
  getDateForTarget(target, time) {
    var hour = parseInt(time.split(":")[0])
    var minute = parseInt(time.split(":")[1])

    var targetStartTime = moment(target.start_time)
    var targetEndTime = moment(target.end_time)

    // var t1 = targetStartTime.clone().hour(hour).minute(minute).second(0)
    // var t2 = targetEndTime.clone().hour(hour).minute(minute).second(0)
    const t1 = moment( this.getTimeBasedOnTargetTime(time, target.start_time) )
    const t2 = moment( this.getTimeBasedOnTargetTime(time, target.end_time) )

    if (t1.isBetween(targetStartTime, targetEndTime, 'minute', '[]')) {
      return t1
    } else {
      if (t2.isBetween(targetStartTime, targetEndTime, 'minute', '[]')) {
        return t2
      } else {
        // This should only happen if a target doesn't last 24h
        return null
      }
    }
  }

  calculatePriceAccordingToTime = (service) => {
    // If there are not start / end time, return 0
    if (!service.for_mn || !service.start_time || !service.end_time) {
      service.quantity = 0
      return 0
    }

    // We need at least one target to calculate the dates
    if (this.props.targetids.filter(t => t.start_time !== null).length == 0) {
      return 0
    }

    var target = this.props.targetids.filter(t => t.start_time !== null)[0];
    if (target.name == null) {
      return 0
    }

    var startDateTime = this.getDateForTarget(target, service.start_time);
    var endDateTime = this.getDateForTarget(target, service.end_time);

    if ((startDateTime == null) || (endDateTime == null)) {
      service.quantity = 0
      return 0
    }

    var offsetMin = (endDateTime - startDateTime) / 60000;

    if(offsetMin <= 0) {
      service.quantity = 0
      return 0
    } else {
      var quantity = Math.ceil(offsetMin / service.unit_scale)
      service.quantity = quantity

      if ((service.degressive_price == null) || (quantity < 2)) {
        return quantity * service.price
      } else {
        return service.price + ((quantity - 1) * (service.degressive_price || service.price));
      }
    }
  }

  calculatePriceOfService = (service) => {
    if (!service.for_mn && (!service.start_time && !service.end_time)) {
      if(service.quantity > 200) { return 0; }

      if (service.degressive_price === null || service.quantity < 2) {
        return (service.quantity || 0) * (service.price || 0)
      } else if (service.quantity === null) {
        return 0
      } else {
        return ((service.price || 0) + ((service.quantity || 0) - 1) * (service.degressive_price || service.price || 0))
      }
    } else {
      return this.calculatePriceAccordingToTime(service)
    }
  }

  calculatePricePerMatch = (services) => {
    if (typeof(services) == 'undefined') {
      services = this.state.services.length == 0 ? this.props.serviceslist : this.state.services;
    }

    if (this.props.targetids.filter(t => t.id !== null && !t.warm_up_match).length === 0) {
      return 0
    }

    this.pricePermatch = services.map(service => {
      return this.calculatePriceOfService(service)
    }).reduce((total, current) => total + current, 0);
    return this.pricePermatch;
  }

  calculatePrice = (services) => {
    //warm_up_match price should be 0
    const numberOfTargets = this.props.targetids.filter(t => (t.id !== null && !t.warm_up_match)).length
    return this.calculatePricePerMatch(services) * numberOfTargets
  }

  showAlert = (event) => {
    event.preventDefault();
    this.addNotification(this.notificationDOMRef, 'Error', 'Nothing has been selected or input is not valid', 'danger')
    event.stopPropagation();
  }

  nextStep = (event) => {
    var services = this.props.edit ? this.props.serviceslist : this.state.services
    var servicesInvalid = services.filter(service => service.isValid == false)
    this.orderIsEmpty() || servicesInvalid.length > 0 ? this.showAlert(event) : this.saveServices()
  }

  changeColorOfInvalidServices = (service) => {
    return !service.isValid ? "not-valid" : ""
  }

  render () {
    let booking_phase = this.props.booking_phase
    let servicesData = this.state.services.length == 0 ? this.props.serviceslist : this.state.services;

    let servicesList = servicesData.map( service => {
      let slotField = null;
      let startTime = null;
      let endTime = null;
      let qtyField = null;

      if (service.should_prompt_slot_number) {

        let slotOptions = [<option value="">Select a slot</option>]

        for (var i=1; i<=booking_phase.slot_count; i++) {
          slotOptions.push(<option value={i}>Slot {i}</option>)
        }

        slotField = (
          <select className="select-slot" onChange={(event) => { this.manageServicesOnChangeHandler(service.id, event, 'slot') } } value={service.slot || ''}>
            {slotOptions}
          </select>
        )
      }

      if (service.for_mn === true){
        startTime = (<DateTime dateFormat={false} timeFormat={'H:mm'} inputProps={{id: "start-time-input", placeholder: 'Start Time'}}
                      onChange={(event) => { this.manageServicesOnChangeHandler(service.id, event, 'start_time') } } value={ service.start_time || '' }/>)
        endTime = (<DateTime dateFormat={false} timeFormat={'H:mm'} inputProps={{id: "end-time-input", placeholder: 'End Time'}}
                   onChange={(event) => { this.manageServicesOnChangeHandler(service.id, event, 'end_time') } } value={ service.end_time || '' }/>)
      }

      if ((slotField == null) && (startTime == null)) {
        qtyField = <input onChange={(event) => { this.manageServicesOnChangeHandler(service.id, event, 'quantity') } } value={ service.quantity || '' } type="text" className='qty' placeholder="Qty"/>
      }

      return (
        <tr className={ this.changeColorOfInvalidServices(service) } key={service.id}>
          <td className='align-top'>{ service.code }</td>
          <td className='align-top'>
            { service.description }
            &nbsp;-&nbsp;
            { (service.serviceIsCustom == true) ? "p.o.a." : service.formatted_price }
          </td>
          <td className="inputs">
            { qtyField }
            { startTime }
            { endTime }
            { slotField }
          </td>
        </tr>
      )
    });

    let table = (
      <table className="table table-sm">
        <tbody>
          { servicesList }
        </tbody>
      </table>
    )

    let order_prices = (
      <div className="order-prices">
        { this.props.targetlist.length > 1 ? <div className="price-per-target">Price per {booking_phase.target_name}: {this.formatPriceByCurrency(servicesData, this.calculatePricePerMatch).join(" - ")}</div> :  <div></div> }
        <div className="total-price">Total Price of the Order: {this.formatPriceByCurrency(servicesData, this.calculatePrice).join(" - ")}</div>
      </div>
    )

    return (
      <div className="col-9 services-box show-scrollbar">
        <NotificationsManager notificationDOMRef={ this.notificationDOMRef }/>
        <div className="services-list">
          <div>
            { this.state.loading ? table : ((this.props.edit === true) ? table : 'Loading...') }
          </div>
          <footer className="footer">
            <div className="container">
              { order_prices }
              <div className="next_button">
                <Link onClick={ (event) => {
                  this.totalPriceByCurrency = this.formatPriceByCurrency(servicesData, this.calculatePrice)
                  this.nextStep(event) }
                } to={ "/orders/new/3" }
                  className="btn btn-primary float-right">
                  Next <i className="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    serviceslist: state.services,
    bf_id: state.order.booking_form_id,
    targetids: state.order.target_ids,
    load: state.loading,
    orderlines: state.orderlines,
    edit: state.isEdit,
    targetlist: state.targets,
    totalPrice: state.totalPrice,
    totalPricePermatch: state.totalPricePermatch,
    totalPriceByCurrency: state.totalPriceByCurrency,
    booking_phase: state.booking_phase
  };
}

const mapDispatchToProps = dispatch => {
  return{
    fetchAllService: (id) => dispatch(actionCreators.fetchServices(id)),
    storeService: (target, teams, rank, warm_up_match, quantity, service, serviceCode, serviceDescription, total, unitPrice, start, end, should_prompt_slot_number, slot, target_id, totalPrice, totalPricePerMatch, currency, totalPriceByCurrency) => dispatch({ type: "STORE_SERVICE", target_name: target, teams: teams,
                                                                    rank: rank, warm_up_match: warm_up_match, quantity: quantity, service: service, serviceCode: serviceCode, serviceDescription: serviceDescription, total: total, unitPrice: unitPrice, start_time: start,
                                                                    end_time: end, should_prompt_slot_number: should_prompt_slot_number, slot: slot, target_id: target_id, total_price: totalPrice, total_price_per_match: totalPricePerMatch, currency: currency, total_price_by_currency: totalPriceByCurrency}),
    storeServices: (services) => dispatch({type: "STORE_SERVICES", services: services}),
    storeTargetIds: (id, name, name_with_kick_off, teams, start_time, end_time, rank, warm_up_match) => dispatch({ type: "STORE_TARGET_IDS", id: id, name: name, name_with_kick_off: name_with_kick_off, teams: teams, start_time: start_time, end_time: end_time, rank: rank, warm_up_match: warm_up_match })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesList)