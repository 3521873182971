import React from "react"
import OrderlineList from '../orderlineform/orderstepthreeform/Orderlinelist';
import "../../../css/Orderstepone.css"
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

class Orderstepthree extends React.Component {
  componentDidMount(){
    window.addEventListener('beforeunload', this.unloadWarning);
    document.querySelector("#org_logo a").setAttribute('data-turbolinks', 'false');
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.unloadWarning);
    document.querySelector("#org_logo a").setAttribute('data-turbolinks', 'true');
  }

  unloadWarning = (e) => {
    e.preventDefault();
    e.returnValue = "Are you sure to leave the page? You'll lose your current order";
  }

  render () {
    let booking_phase = this.props.booking_phase;

    if (booking_phase === null) {
      return <Redirect to="/orders/new"/>;
    }

    return (
      <div>
        <div className='order-step-three'>
          <h1>Create a new order - Step 3</h1>
          <h4>Confirm the following order</h4>
        </div>
        <OrderlineList />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    booking_phase: state.booking_phase
  };
}

const mapDispatchToProps = dispatch => {
  return { }
}

export default connect(mapStateToProps, mapDispatchToProps) (Orderstepthree)